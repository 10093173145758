
import { defineComponent } from 'vue';
import { useInject } from '@/utils/inject';
import { useGetters } from '@/store/mixin';
import SearchTable from '@/components/table/SearchTable.vue';

/**
 * 返现记录，包括激活返现和额外返现
 */
export default defineComponent({
  name: 'RewardRecord',
  components: { SearchTable },
  setup() {
    const { https } = useInject();
    const { userMeta, isAgent } = useGetters();

    const activateOpts = {
      search: {
        criteria: {
          cols: [
            {
              field: 'sn',
              label: '机具号'
            },
            {
              field: 'agentId',
              label: '代理',
              type: 'agent',
              visible: () => !isAgent,
              defVal: () => (isAgent ? userMeta.uid : '')
            },
            {
              field: 'startDate',
              label: '开始时间',
              type: 'date',
              pattern: 'YYYYMMDD'
            },
            {
              field: 'endDate',
              label: '结束时间',
              type: 'date',
              pattern: 'YYYYMMDD'
            }
          ]
        },
        remote: {
          action: https?.payment.pageActReward
        }
      },
      cols: [
        {
          field: 'orderCode',
          label: '交易单号'
        },
        {
          field: 'rewardOrderCode',
          label: '返现单号'
        },
        {
          field: 'agentActAmt',
          label: '返现代理金额'
        },
        {
          field: 'agentId',
          label: '返现代理ID'
        },
        {
          field: 'sonAgentActAmt',
          label: '下级代理金额'
        },
        {
          field: 'userPhone',
          label: '用户手机号'
        },
        {
          field: 'psamNo',
          label: '设备编号'
        },
        {
          field: 'statusDesc',
          label: '状态'
        },
        {
          field: 'remark',
          label: '备注'
        },
        {
          field: 'createTime',
          label: '创建时间'
        },
        {
          field: 'updateTime',
          label: '修改时间'
        },
      ]
    };

    const extraOpts = {
      search: {
        criteria: {
          cols: [
            {
              field: 'sn',
              label: '机具号'
            },
            {
              field: 'agentId',
              label: '代理',
              type: 'agent',
              visible: () => !isAgent,
              defVal: () => (isAgent ? userMeta.uid : '')
            },
            {
              field: 'startDate',
              label: '开始时间',
              type: 'date',
              pattern: 'YYYYMMDD'
            },
            {
              field: 'endDate',
              label: '结束时间',
              type: 'date',
              pattern: 'YYYYMMDD'
            }
          ]
        },
        remote: {
          action: https?.payment.pageExtraReward
        }
      },
      cols: [
        {
          field: 'orderCode',
          label: '交易单号'
        },
        {
          field: 'rewardOrderCode',
          label: '返现单号'
        },
        {
          field: 'amount',
          label: '返现金额',
          width: 100
        },
        {
          field: 'userId',
          label: '用户ID',
          width: 120
        },
        {
          field: 'userPhone',
          label: '用户手机号',
          width: 120
        },
        {
          field: 'psamNo',
          label: '设备编号',
          width: 160
        },
        {
          field: 'statusDesc',
          label: '状态',
          width: 120
        },
        {
          field: 'remark',
          label: '备注',
          width: 160
        },
        {
          field: 'createTime',
          label: '创建时间',
          width: 160
        },
        {
          field: 'updateTime',
          label: '修改时间',
          width: 160
        },
      ]
    };
    return { activateOpts, extraOpts };
  }
});
